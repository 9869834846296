import type { EmblaOptionsType } from 'embla-carousel'
import type { EmblaCarouselVueType } from 'embla-carousel-vue'

export interface UISliderBaseProps {
  /**
   * Embla carousel options
   * @see https://www.embla-carousel.com/api/options/
   */
  options?: EmblaOptionsType

  /**
   * Enable/disable the slider
   * @default true
   */
  enabled?: boolean

  /**
   * Class to be applied to the slider container
   */
  containerClass?: string

  /**
   * Class to be applied to the slides wrapper
   */
  slidesWrapperClass?: string

  /**
   * Class to be applied to each slide
   */
  slideClass?: string

  /**
   * Center content horizontally
   */
  justifyCenter?: boolean
}

export interface UISliderBaseEmits {
  /**
   * Emitted when Embla is initialized
   */
  (event: 'init', api: NonNullable<EmblaCarouselVueType[1]>): void

  /**
   * Emitted when the selected slide changes
   */
  (event: 'select', selectedIndex: number): void

  /**
   * Emitted when the slider starts scrolling
   */
  (event: 'scroll-start'): void

  /**
   * Emitted when the slider stops scrolling
   */
  (event: 'scroll-end'): void

  /**
   * Emitted when the slider is resized
   */
  (event: 'resize'): void
}

export interface UISliderBaseSlots {
  default: () => any
  'button-navigation-prev'?: (props: { disabled: boolean }) => any
  'button-navigation-next'?: (props: { disabled: boolean }) => any
  'bullets-navigation'?: (props: {
    selectedIndex: number
    slidesCount: number
  }) => any
  scrollbar?: (props: { progress: number }) => any
}

export interface UISliderInjectionState {
  api: NonNullable<EmblaCarouselVueType[1]>
  canScrollNext: boolean
  canScrollPrev: boolean
  selectedIndex: number
  slidesCount: number
  scrollProgress: number
  scrollTo: (index: number) => void
  scrollNext: () => void
  scrollPrev: () => void
}

// Injection key for provide/inject
export const SLIDER_INJECTION_KEY = Symbol('slider-state')

<script setup lang="ts">
import useEmblaCarousel from 'embla-carousel-vue'
import type { EmblaCarouselType } from 'embla-carousel'
import type {
  UISliderBaseProps,
  UISliderBaseEmits,
  UISliderInjectionState,
} from './UISliderBase.props'
import { SLIDER_INJECTION_KEY } from './UISliderBase.props'

const props = withDefaults(defineProps<UISliderBaseProps>(), {
  options: () => ({}),
  enabled: true,
  justifyCenter: false,
  containerClass: '',
  slidesWrapperClass: '',
  slideClass: '',
})

const emit = defineEmits<UISliderBaseEmits>()

// Core state
const enabled = ref(props.enabled)
const selectedIndex = ref(0)
const slidesCount = ref(0)
const scrollProgress = ref(0)

// Initialize Embla
const [emblaNode, emblaApi] = useEmblaCarousel({
  ...props.options,
  active: enabled.value,
})

// Reactive state for navigation
const emblaState = reactive<{
  canScrollNext: boolean
  canScrollPrev: boolean
  selectedIndex: number
  slidesCount: number
  scrollProgress: number
  scrollMeasurements: {
    width: number
    scrollWidth: number
  } | null
}>({
  canScrollNext: false,
  canScrollPrev: false,
  selectedIndex: 0,
  slidesCount: 0,
  scrollProgress: 0,
  scrollMeasurements: null,
})

// Update navigation state
function updateState() {
  if (!emblaApi.value) return

  const api = emblaApi.value
  emblaState.canScrollNext = api.canScrollNext()
  emblaState.canScrollPrev = api.canScrollPrev()
  emblaState.selectedIndex = api.selectedScrollSnap()
  emblaState.slidesCount = api.scrollSnapList().length
  emblaState.scrollProgress = api.scrollProgress()
  emblaState.scrollMeasurements = {
    width: api.rootNode()?.getBoundingClientRect().width,
    scrollWidth: api.containerNode()?.scrollWidth,
  }
}

// Navigation methods
const scrollNext = () => {
  emblaApi.value?.scrollNext()
  updateState()
}

const scrollPrev = () => {
  emblaApi.value?.scrollPrev()
  updateState()
}

const scrollTo = (index: number) => {
  if (index < 0 || index >= emblaState.slidesCount) {
    console.warn(
      `Invalid slide index: ${index}. Slide index must be between 0 and ${emblaState.slidesCount - 1}.`
    )
    return
  }

  emblaApi.value?.scrollTo(index)
  updateState()
}

// Event handlers
const handleInit = () => {
  if (!emblaApi.value) return
  updateState()
  emit('init', emblaApi)
}

const handleSelect = () => {
  if (!emblaApi.value) return
  selectedIndex.value = emblaApi.value.selectedScrollSnap()
  emit('select', selectedIndex.value)
  updateState()
}

const handleScroll = () => {
  if (!emblaApi.value) return
  scrollProgress.value = emblaApi.value.scrollProgress()
  updateState()
}

const handleResize = () => {
  if (!emblaApi.value) return
  updateState()
  emit('resize')
}

// Watch for prop changes
watch(
  () => props.enabled,
  newVal => {
    if (emblaApi.value) {
      emblaApi.value.reInit({
        ...props.options,
        active: newVal,
      })
    }
  }
)

watch(
  () => props.options,
  newVal => {
    if (emblaApi.value) {
      emblaApi.value.reInit(newVal)
    }
  },
  { deep: true }
)

// Provide slider state immediately
const sliderState = reactive<UISliderInjectionState>({
  api: emblaApi,
  canScrollNext: false,
  canScrollPrev: false,
  selectedIndex: 0,
  slidesCount: 0,
  scrollProgress: 0,
  scrollTo,
  scrollNext,
  scrollPrev,
})

// Watch for state changes
watch(
  () => emblaState,
  newState => {
    sliderState.canScrollNext = newState.canScrollNext
    sliderState.canScrollPrev = newState.canScrollPrev
    sliderState.selectedIndex = newState.selectedIndex
    sliderState.slidesCount = newState.slidesCount
    sliderState.scrollProgress = newState.scrollProgress
  },
  { deep: true, immediate: true }
)

// Provide the state before mounting
provide(SLIDER_INJECTION_KEY, readonly(sliderState))

// Lifecycle hooks
onMounted(() => {
  if (!emblaApi.value) return

  // Setup event listeners
  emblaApi.value
    .on('init', handleInit)
    .on('select', handleSelect)
    .on('scroll', handleScroll)
    .on('resize', handleResize)

  // Initial state update
  updateState()
})

onUnmounted(() => {
  if (!emblaApi.value) return

  // Remove event listeners
  emblaApi.value
    .off('init', handleInit)
    .off('select', handleSelect)
    .off('scroll', handleScroll)
    .off('resize', handleResize)

  // Cleanup
  emblaApi.value.destroy()
})

// Computed values for scrollbar
const scrollbarMeasurements = computed(() => {
  if (!emblaApi.value) return null
  const api = emblaApi.value
  const viewport = api.rootNode()
  const container = api.containerNode()

  if (!viewport || !container) return null

  return {
    viewportWidth: viewport.getBoundingClientRect().width,
    scrollWidth: container.scrollWidth,
  }
})

// Expose API
defineExpose({
  api: emblaApi,
  scrollTo,
  scrollNext,
  scrollPrev,
})
</script>

<template>
  <div class="slider-base-container">
    <div
      ref="emblaNode"
      class="embla"
      :class="[containerClass]"
      v-bind="$attrs"
    >
      <div
        class="embla__container"
        :class="[
          slidesWrapperClass,
          { 'is-vertical': options?.axis === 'y' },
          { 'md:justify-center': justifyCenter },
        ]"
      >
        <slot />
      </div>
    </div>

    <div class="navigation-buttons-container">
      <div class="navigation-button-prev" @click.stop="scrollPrev">
        <slot
          name="button-navigation-prev"
          :disabled="!emblaState.canScrollPrev"
        />
      </div>

      <div class="navigation-button-next" @click.stop="scrollNext">
        <slot
          name="button-navigation-next"
          :disabled="!emblaState.canScrollNext"
        />
      </div>
    </div>

    <slot
      name="scrollbar"
      :progress="emblaState.scrollProgress"
      :measurements="scrollbarMeasurements"
    />

    <slot
      name="bullets-navigation"
      :selected-index="emblaState.selectedIndex"
      :slides-count="emblaState.slidesCount"
    />
  </div>
</template>

<style lang="scss" scoped>
.slider-base-container {
  position: relative;
  width: 100%;
}

.embla {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.embla__container {
  display: flex;
  height: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  margin: 0;

  &.is-vertical {
    flex-direction: column;
  }
}

.navigation-buttons-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;

  .navigation-button-prev,
  .navigation-button-next {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: auto;
  }

  .navigation-button-prev {
    left: var(--padding-x);
  }

  .navigation-button-next {
    right: var(--padding-x);
  }
}
</style>
